import { getApolloClient } from '@/libs/apollo-client';
import { DocumentNode, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export type Type = 'chartNewAlbumRank' | 'magazines' | 'hopeYouLikePlaylist';

type QueryType = {
  [key in Type]?: { edges: Array<any>; totalCount: number } | null;
};

export function useFetchMoreHandler<T extends QueryType>(
  type: Type,
  doc: DocumentNode
) {
  const { data, fetchMore } = useQuery<T>(doc, {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-first',
    ssr: false,
    client: getApolloClient(),
  });

  const fetchMoreHandler = useMemo(() => {
    if (data?.[type]?.totalCount === data?.[type]?.edges?.length) {
      return undefined;
    }
    const currentData = data?.[type]?.edges;

    const threshold = currentData ? currentData.length - 10 : Infinity;

    let clicked = false;

    return (currentSlide: number) => {
      if (clicked || currentSlide < threshold) {
        return;
      }
      clicked = true;
      fetchMore({
        variables: {
          num: 30,
          cursor: currentData?.[currentData.length - 1].cursor,
        },
      });
    };
  }, [data, fetchMore, type]);

  return {
    data: data?.[type]?.edges || [],
    fetchMoreHandler,
    totalCount: data?.[type]?.totalCount || 0,
  };
}
