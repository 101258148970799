import { useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';

export interface Width {
  [key: string]: number | string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getBreakPoint(windowWidth: number, width: Width) {
  if (windowWidth >= 1280 && 'x3l' in width) {
    return 'x3l';
  } else if (windowWidth >= 1025 && 'x2l' in width) {
    return 'x2l';
  } else if (windowWidth >= 993 && 'xl' in width) {
    return 'xl';
  } else if (windowWidth >= 768 && 'lg' in width) {
    return 'lg';
  } else if (windowWidth >= 594 && 'md' in width) {
    return 'md';
  } else if (windowWidth >= 425 && 'sm' in width) {
    return 'sm';
  } else if (windowWidth >= 375 && 'xs' in width) {
    return 'xs';
  } else if (windowWidth >= 320 && 'xxs' in width) {
    return 'xxs';
  }

  return 'base';
}

function calcSliders(containerWidth: number, itemWidth: number | string) {
  if (typeof itemWidth === 'string') {
    return 100 / parseFloat(itemWidth);
  }
  const slides = containerWidth / itemWidth;

  return slides < 1 ? 1 : slides;
}

function useCarousel(containerId: string, width: Width) {
  const [slidesToShow, setSlidesToShow] = useState(-1);

  useEffect(() => {
    const container = document.getElementById(containerId) as HTMLElement;

    const resizeHandler = () => {
      const situation = getBreakPoint(window.innerWidth, width);

      setSlidesToShow(calcSliders(container.clientWidth, width[situation]));
    };

    resizeHandler();
    const throttledHandler = throttle(100, resizeHandler);

    window.addEventListener('resize', throttledHandler);

    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
  }, [containerId, width]);

  return slidesToShow;
}

export default useCarousel;
