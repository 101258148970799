import { Button, Box } from '@chakra-ui/react';
import { CustomArrowProps } from 'react-slick';

interface IPrevArrowButtonProps {
  type: string;
}

export function PrevArrowButton({
  onClick,
  currentSlide,
  type,
}: CustomArrowProps & IPrevArrowButtonProps) {
  return (
    <>
      {currentSlide !== undefined && currentSlide > 0 && (
        <Button
          variant={`${type}CarouselButton`}
          display={{ base: 'none', lg: 'inline-flex' }}
          left={{ lg: '-19px', base: '11px' }}
          p="13px"
          onClick={onClick}
        >
          <Box
            _after={{
              boxSize: '14px',
              content: '""',
              clipPath:
                'polygon(20% 50%,70% 100%,80% 90%,40% 50%,80% 10%,70% 0%)',
              display: 'block',
              background: 'black',
            }}
          />
        </Button>
      )}
    </>
  );
}
