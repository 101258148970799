import gql from 'graphql-tag';

const albumFragment = gql`
  fragment AlbumPreviewFragment on Album {
    albumId
    name
    imageUrl
    artist {
      artistId
      name
    }
  }
`;

export default albumFragment;
