import {
  MagazineInfoHomeQuery,
  MainPageQuery,
  NewAlbumCollectionQuery,
  RecommendPlaylistQuery,
} from '@/graphql/generated/schema';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  createClientSideApolloclient,
  createServerSideApolloClient,
} from './lib';

export interface MainPageCache {
  [ROOT_QUERY: string]: {
    magazines: MainPageQuery['magazines'];
    hopeYouLikePlaylist: MainPageQuery['hopeYouLikePlaylist'];
    chartNewAlbumRank: MainPageQuery['chartNewAlbumRank'];
    __typename: string;
  };
}

export interface RecommendPlaylistCache {
  [ROOT_QUERY: string]: {
    hopeYouLikePlaylist: RecommendPlaylistQuery['hopeYouLikePlaylist'];
    __typename: string;
  };
}

export interface MagazineCollectionCache {
  [ROOT_QUERY: string]: {
    magazines: MagazineInfoHomeQuery['magazines'];
    __typename: string;
  };
}

export interface NewAlbumCollectionCache {
  [ROOT_QUERY: string]: {
    chartNewAlbumRank: NewAlbumCollectionQuery['chartNewAlbumRank'];
    __typename: string;
  };
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function getApolloClient<T extends NormalizedCacheObject>(cache?: T) {
  if (typeof window === 'undefined') {
    return createServerSideApolloClient();
  }

  if (!apolloClient) {
    apolloClient = createClientSideApolloclient();
  }

  if (cache) {
    apolloClient.restore(cache);
  }

  return apolloClient;
}
