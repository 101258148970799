import gql from 'graphql-tag';

const chartFragment = gql`
  fragment ChartFragment on Chart {
    chartId
    title: name
    update: description
    tracks {
      status
      trackInfo: track {
        trackId
        trackTitle: name
        album {
          albumId
          albumTitle: name
          imageUrl
        }
        artist {
          artistId
          artistName: name
          imageUrl
        }
      }
    }
  }

  fragment ChartPreviewFragment on Chart {
    chartId
    title: name
    tracks {
      status
      trackInfo: track {
        trackId
        trackTitle: name
        album {
          albumId
          albumTitle: name
          imageUrl
        }
        artist {
          artistId
          artistName: name
        }
      }
    }
  }
`;

export default chartFragment;
