import gql from 'graphql-tag';

const artistFragment = gql`
  fragment ArtistFragment on Artist {
    artistId
    name
  }

  fragment ArtistPreviewFragment on Artist {
    artistId
    name
    imageUrl
  }

  fragment ArtistBaseInfoFragment on Artist {
    artistId
    name
    aliases {
      source
      value
    }
  }
`;

export default artistFragment;
