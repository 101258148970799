interface Aritst {
  artistId: number;
  name: string;
  imageUrl: string;
}

interface Album {
  albumId: number;
  name: string;
  imageUrl: string;
}

export function convertAlbumNodeData({
  node,
}: {
  node: Album & { artist?: Omit<Aritst, 'imageUrl'> | null };
}) {
  return {
    id: `album-${node.albumId}`,
    title: node.name,
    titleUrl: `/album/${node.albumId}`,
    subTitle: node.artist?.name || undefined,
    subTitleUrl: node.artist ? `/artist/${node.artist.artistId}` : undefined,
    imageUrl: node.imageUrl,
    type: 'album' as const,
    picStyle: { size: 'default' as const },
  };
}

interface Playlist {
  playlistId: number;
  title: string;
  subTitle?: string | null;
  description?: string | null;
  imageUrl: string;
}

export function convertPlaylistNodeData({ node }: { node: Playlist }) {
  return {
    title: node.title,
    titleUrl: `/playlist/${node.playlistId}`,
    subTitle: node.description || undefined,
    subTitleUrl: `/playlist/${node.playlistId}`,
    imageUrl: node.imageUrl,
    type: 'album' as const,
    picStyle: { size: 'default' as const },
  };
}

export function convertPlaylistData(data: Playlist) {
  return {
    title: data.title,
    titleUrl: `/playlist/${data.playlistId}`,
    subTitle: data.description || undefined,
    subTitleUrl: `/playlist/${data.playlistId}`,
    imageUrl: data.imageUrl,
    type: 'album' as const,
    picStyle: { size: 'default' as const },
  };
}
