import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import getConfig from 'next/config';
import gql from 'graphql-tag';
import { createFragmentRegistry } from '@apollo/client/cache';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import artistFragment from '@/graphql/schema/fragment/artistFragment';
import albumFragment from '@/graphql/schema/fragment/albumFragment';
import trackFragment from '@/graphql/schema/fragment/trackFragment';
import chartFragment from '@/graphql/schema/fragment/chartFragment';
import playlistFragment from '@/graphql/schema/fragment/playlistFragment';

const { publicRuntimeConfig } = getConfig();

const httpLink = new HttpLink({
  useGETForQueries: true,
  uri: `${publicRuntimeConfig.INTERNAL_DATA_FETCH_API}/graphql`,
});

const persistedQueriesLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
  disable: ({ networkError }) =>
    networkError
      ? (networkError as ServerError)?.statusCode === 404 ||
        (networkError as ServerError)?.statusCode >= 500
      : false,
});

const link = persistedQueriesLink.concat(httpLink);

export function createServerSideApolloClient() {
  return new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(gql`
        ${artistFragment}
        ${albumFragment}
        ${trackFragment}
        ${chartFragment}
        ${playlistFragment}
      `),
    }),
  });
}

export function createClientSideApolloclient() {
  return new ApolloClient({
    ssrMode: false,
    link,
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(gql`
        ${artistFragment}
      `),
      typePolicies: {
        Query: {
          fields: {
            magazines: {
              keyArgs: false,
              read(existing) {
                return existing;
              },
              merge(existing, incoming) {
                return {
                  edges: incoming
                    ? [...existing.edges, ...incoming.edges]
                    : [...existing.edges],
                  totalCount: incoming
                    ? incoming.totalCount
                    : existing.totalCount,
                  __typename: incoming
                    ? incoming.__typename
                    : existing.__typename,
                };
              },
            },
            hopeYouLikePlaylist: {
              keyArgs: false,
              read(existing) {
                return existing;
              },
              merge(existing, incoming) {
                return {
                  edges: incoming
                    ? [...existing.edges, ...incoming.edges]
                    : [...existing.edges],
                  totalCount: incoming
                    ? incoming.totalCount
                    : existing.totalCount,
                  __typename: incoming
                    ? incoming.__typename
                    : existing.__typename,
                };
              },
            },
            chartNewAlbumRank: {
              keyArgs: false,
              read(existing) {
                return existing;
              },
              merge(existing, incoming) {
                return {
                  edges: incoming
                    ? [...existing.edges, ...incoming.edges]
                    : [...existing.edges],
                  totalCount: incoming
                    ? incoming.totalCount
                    : existing.totalCount,
                  __typename: incoming
                    ? incoming.__typename
                    : existing.__typename,
                  name: existing.name,
                };
              },
            },
          },
        },
      },
    }),
  });
}
