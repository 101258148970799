import { Button, Box } from '@chakra-ui/react';
import { CustomArrowProps } from 'react-slick';

interface INextArrowButtonProps {
  type: string;
  lastCount: number;
}

export function NextArrowButton({
  onClick,
  currentSlide,
  type,
  lastCount,
}: CustomArrowProps & INextArrowButtonProps) {
  return (
    <>
      {Math.ceil(currentSlide || 0) !== lastCount && (
        <Button
          variant={`${type}CarouselButton`}
          display={{ base: 'none', lg: 'inline-flex' }}
          right={{ lg: '-19px', base: '11px' }}
          onClick={onClick}
          p="13px"
        >
          <Box
            _after={{
              boxSize: '14px',
              content: '""',
              clipPath:
                'polygon(20% 50%,70% 100%,80% 90%,40% 50%,80% 10%,70% 0%)',
              display: 'block',
              background: 'black',
              transform: 'rotate(-180deg)',
            }}
          />
        </Button>
      )}
    </>
  );
}
