import gql from 'graphql-tag';

const trackFragment = gql`
  fragment TrackPreviewFragment on Track {
    trackId
    name
    album {
      imageUrl
      albumId
      name
    }
    artist {
      artistId
      name
    }
  }
`;

export default trackFragment;
