import { Picture } from '@/components/Common/Picture';
import { IsotoxalFigure } from '@/components/Common/Picture/Picture';
import { Box, Text, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import { memo } from 'react';

const titleProps = {
  maxH: { md: '34px', base: '20px' },
  lineHeight: { md: '17px', base: '20px' },
  fontSize: { md: '14px', base: '17px' },
  mt: '10px',
  color: 'black',
} as const;

interface ICommonPreviewProps {
  id?: string;
  title: string;
  titleUrl: string;
  subTitle?: string;
  subTitleUrl?: string;
  imageUrl: string;
  type: 'album' | 'artist';
  picStyle: IsotoxalFigure;
}

export function CommonPreview({
  id,
  title,
  titleUrl,
  subTitle,
  subTitleUrl,
  imageUrl,
  type,
  picStyle,
}: ICommonPreviewProps) {
  return (
    <Flex id={id} direction="column">
      <Link href={titleUrl}>
        <Picture<IsotoxalFigure>
          iconImageUrl={imageUrl}
          title={title}
          type={type}
          style={picStyle}
        />
      </Link>
      <Box textAlign={picStyle.variant === 'circle' ? 'center' : 'left'}>
        <Text
          className="ellipsis_text"
          {...titleProps}
          sx={{ WebkitLineClamp: { md: 2, base: 1 } }}
        >
          <Link href={titleUrl}>{title}</Link>
        </Text>
        {subTitle && subTitleUrl && (
          <Text
            className="ellipsis_text"
            fontSize={{ md: '13px', base: '15px' }}
            lineHeight={{ md: '17px', base: '20px' }}
            maxH={{ md: '34px', base: '20px' }}
            color="gray.7"
            pt={{ md: '5px', base: '0px' }}
            sx={{ WebkitLineClamp: { md: 2, base: 1 } }}
          >
            <Link href={subTitleUrl}>{subTitle}</Link>
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export const MemoCommonPreview = memo(
  CommonPreview,
  (prev, next) => prev.id === next.id
);
