import useCarousel, { Width } from '@/hooks/useCarousel';
import { Box, useStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NextArrowButton } from './NextArrowButton';
import { PrevArrowButton } from './PrevArrowButton';

export type CarouselVariant = 'middle' | 'chart';

interface ICarouselProps {
  variant: CarouselVariant;
  containerId: string;
  fetchMoreHandler?: (currentSlide: number) => void;
  children: ReactNode;
  totalNum: number;
}

export default function BaseCarousel({
  variant,
  containerId,
  fetchMoreHandler,
  children,
  totalNum,
}: ICarouselProps) {
  const styles = useStyleConfig('Carousel', { variant });

  const numberToShow = useCarousel(containerId, styles.width as Width);

  const flooredNum = Math.floor(numberToShow);

  return (
    <Box
      sx={{
        '.slick-list': { height: styles.height },
        '.slick-track': { m: '0px' },
      }}
    >
      <Slider
        infinite={false}
        speed={500}
        slidesToShow={numberToShow}
        slidesToScroll={flooredNum}
        nextArrow={
          <NextArrowButton type={variant} lastCount={totalNum - flooredNum} />
        }
        prevArrow={<PrevArrowButton type={variant} />}
        afterChange={fetchMoreHandler}
      >
        {numberToShow !== -1 && children}
      </Slider>
    </Box>
  );
}
