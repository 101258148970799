import BaseCarousel, {
  CarouselVariant,
} from '@/components/Common/BaseCarousel';
import { SectionLayout } from '@/components/Common/Layout/SectionLayout';
import { Type, useFetchMoreHandler } from '@/hooks/useFetchMoreHandler';
import { DocumentNode } from '@apollo/client/link/core/types';
import { MemoExoticComponent, PropsWithRef } from 'react';

interface IFetchMoreCarouselSectionProps<T, ConvertedT> {
  id: Type;
  queryDoc: DocumentNode;
  sectionTitle: string;
  href?: string;
  carouselVariant?: CarouselVariant;
  items?: T[];
  converter: (props: T) => PropsWithRef<ConvertedT>;
  Component: MemoExoticComponent<(props: ConvertedT) => JSX.Element>;
}

export function FetchMoreCarouselSection<T, ConvertedT>({
  id,
  queryDoc,
  sectionTitle,
  href,
  carouselVariant = 'middle',
  converter,
  Component,
}: IFetchMoreCarouselSectionProps<T, ConvertedT>) {
  const { data, fetchMoreHandler } = useFetchMoreHandler(id, queryDoc);

  return (
    <>
      <SectionLayout id={id} sectionTitle={sectionTitle} href={href}>
        <BaseCarousel
          variant={carouselVariant}
          containerId={id}
          fetchMoreHandler={fetchMoreHandler}
          totalNum={data.length}
        >
          {typeof window !== 'undefined' &&
            data.map((item, index) => (
              <Component key={index} {...converter(item)} />
            ))}
        </BaseCarousel>
      </SectionLayout>
    </>
  );
}
