import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Link from 'next/link';

const textProps = {
  as: 'h3',
  fontSize: { base: '19px', md: '17px' },
  lineHeight: '38px',
  fontWeight: '700',
  color: 'black',
  mb: { base: '7px', md: '2px' },
  textAlign: 'left',
  position: 'relative',
} as const;

function MoreLink({ href, title }: { href: string; title: string }) {
  return (
    <Link href={href}>
      <Box
        {...textProps}
        _after={{
          content: '""',
          clipPath: 'polygon(6% 0%, 0% 6%, 43% 50%, 0% 94%, 6% 100%, 55% 50%)',
          background: 'black',
          boxSize: '17px',
          position: 'absolute',
          top: '0',
          bottom: '0',
          margin: 'auto',
          ml: '7px',
        }}
      >
        {title}
      </Box>
    </Link>
  );
}

interface ISectionLayoutProps {
  id?: string;
  sectionTitle: string;
  href?: string;
  isBordered?: boolean;
  children: ReactNode;
}

export function SectionLayout({
  id,
  sectionTitle,
  href,
  isBordered = true,
  children,
}: ISectionLayoutProps) {
  const boxProps: { [key: string]: any } = {
    p: { base: '0px 0px 33px', md: '18px 0px 18px' },
    borderTop: isBordered ? { base: '', md: '1px solid #ededed' } : '',
  };

  if (id) {
    boxProps.id = id;
  }

  return (
    <Box {...boxProps}>
      {href ? (
        <MoreLink href={href} title={sectionTitle} />
      ) : (
        <Text {...textProps}>{sectionTitle}</Text>
      )}
      {children}
    </Box>
  );
}
