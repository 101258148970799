import BaseCarousel, {
  CarouselVariant,
} from '@/components/Common/BaseCarousel';
import { SectionLayout } from '@/components/Common/Layout/SectionLayout';

interface ISeeMoreSectionProps<T, ConvertedT> {
  id: string;
  sectionTitle: string;
  href?: string;
  carouselVariant?: CarouselVariant;
  items?: T[];
  converter: (props: T) => ConvertedT;
  Component: (props: ConvertedT) => JSX.Element;
  isBordered?: boolean;
}

export function NormalCarouselSection<T, ConvertedT>({
  id,
  sectionTitle,
  href,
  carouselVariant = 'middle',
  items = [],
  converter,
  Component,
  isBordered = true,
}: ISeeMoreSectionProps<T, ConvertedT>) {
  return (
    <>
      {items.length > 0 && (
        <SectionLayout
          id={id}
          sectionTitle={sectionTitle}
          href={href}
          isBordered={isBordered}
        >
          <BaseCarousel
            variant={carouselVariant}
            containerId={id}
            totalNum={items.length}
          >
            {typeof window !== 'undefined' &&
              items.map((item, index) => (
                <Component key={index} {...converter(item)} />
              ))}
          </BaseCarousel>
        </SectionLayout>
      )}
    </>
  );
}
