import gql from 'graphql-tag';

const playlistFragment = gql`
  fragment PlaylistPreviewFragment on Playlist {
    playlistId
    title
    subTitle
    description
    imageUrl
  }
`;

export default playlistFragment;
